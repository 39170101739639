import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { LoaderService } from '../servicios/loader.service';
import { finalize } from 'rxjs/operators';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private _loader: LoaderService) { }
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this._loader.show();
		return next.handle(req).pipe(
			finalize(() => this._loader.hide())
		);
	}
}
