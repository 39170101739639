import { Component, ViewChildren, QueryList } from '@angular/core';
import { Platform, ModalController, IonRouterOutlet, NavController, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { LoaderService, ConfiguracionService, AgendaService} from './servicios/servicios.index';
import { Device } from '@capacitor/core';
import {
	Plugins,
	PushNotification,
	PushNotificationToken,
	PushNotificationActionPerformed,
	Capacitor,
	// NetworkStatus
} from '@capacitor/core';
const { PushNotifications, LocalNotifications, Network, Modals } = Plugins;
@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {
	token = 'token';
	appPages = [];
	lastTimeBackPress = 0;
	timePeriodToExit = 2000;
	@ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
	constructor(
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private navCtrl: NavController,
		private pf: Platform,
		private toastController: ToastController,
		private router: Router,
		private _stg: Storage,
		public modalCtrl: ModalController,
		private _load: LoaderService,
		private _config: ConfiguracionService,
		private _ag: AgendaService,
	) {
		this.initializeApp();
		// console.log(Device.getInfo());
	}
	initializeApp() {
		this.pf.ready().then(() => {
			this.statusBar.styleDefault();
			this.statusBar.styleLightContent();
			this.splashScreen.hide();
			Network.getStatus().then(e => {
				if(e.connected) {
					// preguntar en firestore si se borran los datos locales
					this._config.configs().subscribe(rows => {
						if(rows){
							rows.forEach((row: any) => {
								if (row.id == 'cleardb' && row.cleardb) {
									this._stg.clear();
									console.log('logapp:Limpiando localStorage:' + row.cleardb);
									this.cargarDatos();
								}
							} );
						}
					});
					this.listmenu();
				}
			})
			this._load.show();
			this.notificaciones();
			// this.navCtrl.navigateRoot('/home');
			this.navCtrl.navigateRoot('/onboarding');
		});
		this.backButtonEvent();
		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this._load.show();
					break;
				}
				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError:
				default: {
					this._load.hide();
					break;
				}
			}
		});
	}
	async notificacionlocal(noti: any) {
		await LocalNotifications.schedule({
			notifications: [
				{
					title: noti.title,
					body: noti.body,
					id: new Date().getUTCMilliseconds(),
					schedule: { at: new Date(Date.now() + 100) },
					extra: noti.data,
					actionTypeId: 'OPEN_PRODUCT'
				}
			]
		});
	}
	backButtonEvent() {
		this.pf.backButton.subscribe(async () => {
			try {
				const element = await this.modalCtrl.getTop();
				if (element) {
					element.dismiss();
					return;
				}
			} catch (error) {
				console.log('logapp: backButtonEvent:' + error);
			}
			this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
				if (outlet && outlet.canGoBack()) {
					outlet.pop();
				} else if (this.router.url === '/home' || this.router.url === '/onboarding') {
					if (
						new Date().getTime() - this.lastTimeBackPress <
						this.timePeriodToExit
					) {
						this.cerraraplicacion();
					} else {
						this.showToast();
						this.lastTimeBackPress = new Date().getTime();
					}
				}
			});
		});
	}
	async showToast() {
		const toast = await this.toastController.create({
			message: 'Presione de nuevo para salir de la aplicación.',
			mode: 'ios',
			animated: true,
			duration: 2000
		});
		toast.present();
	}
	cerraraplicacion() {
		if(navigator['app']) {
			navigator['app'].exitApp();
		}
	}
	notificaciones() {
		const isPush = Capacitor.isPluginAvailable('PushNotifications');
		console.log('logapp: notificaciones:'+ JSON.stringify(this.pf.platforms()));
		if (isPush) {
			if (this.pf.is('android') || this.pf.is('ios') || this.pf.is('mobile')) {
				PushNotifications.requestPermission().then(result => {
					if (result.granted) {
						PushNotifications.register();
						// Register with Apple / Google to receive push via APNS/FCM
					}
					console.log('logapp: requestPermission:' + JSON.stringify(result));
				});
				// PushNotifications.register();
				PushNotifications.addListener('registration',
					(token: PushNotificationToken) => {
						console.log('logapp:token de registro: ' + token.value);
						this.token = token.value;
						if (token.value) {
							// Modals.alert({ title: 'Token', message: token.value });
						}
					}
				);
				PushNotifications.addListener('pushNotificationReceived',
					async (notification: PushNotification) => {
						console.log('logapp:notificacion recivida:' + JSON.stringify(notification));
						this.notificacionlocal(notification);
					}
				);
				PushNotifications.addListener('pushNotificationActionPerformed',
					async (notification: PushNotificationActionPerformed) => {
						const data = notification.notification.data;
						console.log('logapp: pushNotificationActionPerformed: ' + JSON.stringify(data));
						if (data.noteId) {
							// this.router.navigateByUrl(`/notificaciones:${data.noteId}`);
							this.navCtrl.navigateRoot(`/notificaciones:${data.noteId}`);
						}
						console.log('logapp:Segundo plano notificaciones: ' + JSON.stringify(notification));
					}
				);
			}
		}
	}
	async cargarDatos() {
		await this._stg.get('conferencistas').then((row: any) => {
			if (row === null) {
				this._load.show();
				this._ag.conferencistas().subscribe(filas => {
					this._stg.set('conferencistas', filas);
					this._load.hide();
				});
			}
		});
		await this._stg.get('charlas').then((row: any) => {
			if (row === null) {
				this._load.show();
				this._ag.charlas().subscribe(filas => {
					this._stg.set('charlas', filas);
					this._load.hide();
				});
			}
		});
	}
	async listmenu() {
		await this._stg.get('listamenu').then((row: any) => {
			if (row === null) {
				this._load.show();
				this._config.listamenu().subscribe(filas => {
					this.appPages = filas;
					this._stg.set('listamenu', filas);
					this._load.hide();
				});
			} else {
				this.appPages = row;
			}
		});
	}
}
