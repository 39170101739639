import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
const routes: Routes = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full'
	},
	{
		path: 'home',
		loadChildren: () => import('./paginas/home/home.module').then(m => m.HomePageModule)
	},
	{
		path: 'onboarding',
		loadChildren: () => import('./paginas/onboarding/onboarding.module').then(m => m.OnboardingPageModule)
	},
	{
		path: 'eventdetail',
		loadChildren: () => import('./paginas/eventdetail/eventdetail.module').then(m => m.EventdetailPageModule)
	},
	{
		path: 'expositores',
		loadChildren: () => import('./paginas/expositores/expositores.module').then(m => m.ExpositoresPageModule)
	},
	{
		path: 'patrocinadores',
		loadChildren: () => import('./paginas/patrocinadores/patrocinadores.module').then(m => m.PatrocinadoresPageModule)
	},
	{
		path: 'noticias',
		loadChildren: () => import('./paginas/noticias/noticias.module').then(m => m.NoticiasPageModule)
	},
	{
		path: 'conferencistas',
		loadChildren: () => import('./paginas/conferencistas/conferencistas.module').then(m => m.ConferencistasPageModule)
	},
	{
		path: 'detailconferencista',
		loadChildren: () => import('./paginas/detailconferencista/detailconferencista.module').then(m => m.DetailconferencistaPageModule)
	},
	{
		path: 'vercharla',
		loadChildren: () => import('./paginas/vercharla/vercharla.module').then(m => m.VercharlaPageModule)
	},
	{
		path: 'mapasitio',
		loadChildren: () => import('./paginas/mapasitio/mapasitio.module').then(m => m.MapasitioPageModule)
	},
	{
		path: 'notas',
		loadChildren: () => import('./paginas/notas/notas.module').then(m => m.NotasPageModule)
	},  {
    path: 'protocolo',
    loadChildren: () => import('./paginas/protocolo/protocolo.module').then( m => m.ProtocoloPageModule)
  }


];
@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
