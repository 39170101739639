import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
@Injectable({
	providedIn: 'root'
})
export class NotasService {
	constructor(private db: AngularFirestore) {
		// this.itemsCollection = db.collection('noticias', ref => ref.orderBy('orden', 'desc'));
	}
	notas(param: any) {
		if(param.idcharla){
			return this.db.collection('notas').doc(param.iduser).collection('listnotas', ref => ref.where('charla','==',param.idcharla)).valueChanges({ idField: 'id' });

		} else {
			return this.db.collection('notas').doc(param.iduser).collection('listnotas').valueChanges({ idField: 'id' });
		}
	}
	addnota(param: any) {
		this.db.collection('notas').doc(param.user).collection('listnotas').doc(param.id).set({
			id: param.id,
			nota:param.nota,
			user:param.user,
			fecha:param.fecha,
			charla: param.charla,
			nombre: param.nombre
		  })
	}
	eliminar(param: any) {
		this.db.collection('notas').doc(param.user).collection('listnotas').doc(param.id).delete();
	}
}
