import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
@Injectable({
	providedIn: 'root'
})
export class ConfiguracionService {
	constructor(private db: AngularFirestore) {}
	configs() {
		return this.db.collection('configuracion').valueChanges({ idField: 'id' });
	}
	listamenu() {
		return this.db.collection('listamenu', ref => ref.orderBy('order')).valueChanges({ idField: 'id' });
	}
}
