import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
@Injectable({
	providedIn: 'root'
})
export class NoticiasService {
	private itemsCollection: AngularFirestoreCollection;
	constructor(db: AngularFirestore) {
		this.itemsCollection = db.collection('noticias', ref => ref.orderBy('orden', 'desc'));
	}
	items() {
		return this.itemsCollection.snapshotChanges().pipe(
			map(actions => actions.map(a => {
				return a.payload.doc.data();
			}))
		);
	}
}
