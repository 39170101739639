import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
@Injectable({
	providedIn: 'root'
})
export class ExpositoresService {
	private itemsCollection: AngularFirestoreCollection;
	constructor(db: AngularFirestore) {
		this.itemsCollection = db.collection('expositores');
	}
	items() {
		return this.itemsCollection.snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			}))
		);
	}
}
