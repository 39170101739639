export const environment = {
	production: true,
	isios: false,
	xxxxdb: {
		apiKey: 'AIzaSyC0QOdsB9ek-Kvw-ZoD_n7baXBiI2AQazc',
		authDomain: 'ccsapp-898c5.firebaseapp.com',
		databaseURL: 'https://ccsapp-898c5.firebaseio.com',
		projectId: 'ccsapp-898c5',
		storageBucket: 'ccsapp-898c5.appspot.com',
		messagingSenderId: '471746125722',
		appId: '1:471746125722:web:71b8100ca1b2c5471fa423'
	},
	appPages: [
		{
			title: 'Inicio',
			url: '/home',
			icon: 'home'
		},
		{
			title: 'Conferencistas',
			url: '/conferencistas',
			icon: 'chatbox-outline'
		},
		{
			title: 'Expositores',
			url: '/expositores',
			icon: 'people-outline'
		},
		{
			title: 'Patrocinadores',
			url: '/patrocinadores',
			icon: 'briefcase-outline'
		},
		{
			title: 'Noticias',
			url: '/noticias',
			icon: 'book'
		}
	]
};
