import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	@Input() titulo: string;
	@Input() atras: string;
	isios = false;

	constructor(
		private navCtrl: NavController
	) {
		this.isios = environment.isios;
	}

	btnatras() {
		this.navCtrl.back();
	}
}
