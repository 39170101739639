import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
@Injectable({
	providedIn: 'root'
})
export class AgendaService {
	constructor(private db: AngularFirestore) {
	}
	charlas() {
		return this.db.collection('charlas', ref => ref.orderBy('tiempo')).valueChanges({ idField: 'id' });
	}
	conferencistas() {
		return this.db.collection('conferencistas').snapshotChanges().pipe(
			map(actions => actions.map(a => {
				return a.payload.doc.data();
			}))
		);
	}
	dias() {
		return this.db.collection('dias', ref => ref.orderBy('order')).snapshotChanges().pipe(
			map(actions => actions.map(a => {
				return a.payload.doc.data();
			}))
		);
	}
	onboarding() {
		return this.db.collection('onboarding', ref => ref.orderBy('order')).valueChanges({ idField: 'id' });
	}
	destacados() {
		return this.db.collection('destacados', ref => ref.orderBy('order')).valueChanges({ idField: 'id' });
	}
	confighome() {
		return this.db.collection('confighome').valueChanges();
	}
}
